import React, { useState, useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";

const ProductTable = ({ products, productCategories, stores, setCurrentProduct, updateGlobalAvailability, updateAvailability, handleEditClick, handleDelete }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Barcode ref for each product
  const barcodeRefs = useRef({});

  useEffect(() => {
    products.forEach(product => {
      if (barcodeRefs.current[product._id] && product.barcode) {
        JsBarcode(barcodeRefs.current[product._id], product.barcode, {
          format: "EAN13",
          lineColor: "#0b0b0b",
          width: 1,
          height: 50,
          displayValue: true,
        });
      }
    });
  }, [products]); // Re-run effect when the products change

  // Filter products based on search term
  const filteredProducts = products?.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredProducts?.length ?? 1 / itemsPerPage);
  const displayedProducts = filteredProducts?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div>
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by name..."
        className="form-control mb-3"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1); // Reset to first page when searching
        }}
      />

      {/* Table */}
      <table className="table table-bordered table-responsive table-striped w-100">
        <thead>
          <tr>
            <th className="col-2">Bar code</th>
            <th>Image</th>
            <th>Name</th>
           
            <th>Disponibilité</th>
           
            <th>Store Availability</th>
            <th>Stock</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedProducts.map((product) => (
            <tr key={product._id}>
              <td  width="100px">
                <svg ref={el => barcodeRefs.current[product._id] = el} width={100}></svg>
               
              </td>
              <td>
                <img src={process.env.REACT_APP_DATA_PIC_PATH + `/${product.url_product}`} width={100} />
              </td>
              <td>{product.name}</td>
             
              <td>
                <input
                  type="checkbox"
                  checked={product.availability}
                  onChange={() => updateGlobalAvailability(product._id, !product.availability)}
                />
              </td>
              
              <td>
                {product.stores.map((store, index) => (
                  <div key={index} className={`badge text-bg-${store.availability ? "success" : "danger"} mx-1`}>
                    <span>{stores.find(s => s.value === store.store_id)?.label || "Inconnu"}</span>
                    <input
                      type="checkbox"
                      checked={store.availability}
                      onChange={(e) => updateAvailability(product._id, store.store_id, e.target.checked)}
                    />
                  </div>
                ))}
              </td>
              <td className="w-auto">
                {product.stores.map((store, index) => (
                  <div key={index} className="bg-info m-1 p-2">
                    <span>
                      <span className="badge text-bg-warning">{store.stock ?? 0}</span> {stores.find(s => s.value === store.store_id)?.label || "Inconnu"}
                    </span>
                    <button className="btn btn-primary m-2"><i className="fa-solid fa-pen-to-square"></i></button>
                  </div>
                ))}
              </td>
              <td>
                <button className="btn btn-warning m-1" onClick={() => handleEditClick(product)}>Modify</button>
                <button className="btn btn-danger m-1" onClick={() => handleDelete(product._id)}>Delete</button>
                <button className="btn btn-primary m-1" onClick={() => setCurrentProduct(product)}  data-bs-toggle="modal"
                data-bs-target="#exampleModal"> Détails</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-center mt-3">
        <button
          className="btn btn-secondary mx-1"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span className="align-self-center">Page {currentPage} of {totalPages}</span>
        <button
          className="btn btn-secondary mx-1"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductTable;
