import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
const API_URL = process.env?.REACT_APP_PEGASUS_API_URL;

function StoreShop({products,setProducts,productCategories,setProductCategories}) {
   
    const [stores, setStores] = useState([]); // Store list for dropdown

    const [selectCategory, setSelectedCategory] = useState(null);
    useEffect(() => {
        if(products.length ==0)
        fetchProducts();
        fetchStores(); // Fetch stores for dropdown
    }, []);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const openModal = (product) => {
        setSelectedProduct(product);
    };

    const closeModal = () => {
        setSelectedProduct(null);
    };


    const fetchProducts = async () => {
        const response = await axios.get(`${API_URL}/products`, {
            headers: {
                'ENCRYKEY': '3a7f1b9c4d2e8f0a5b6c754d8e9f0adc3d4e5f6a7dc9d0e1f2a3b4c5d6e7f8a9b0',
            },
        });
        setProducts(response.data.map(product => ({ ...product, quantity: 0 })));
        const response_ = await axios.get(`${API_URL}/productsCategories`, {
            headers: {
                'ENCRYKEY': '3a7f1b9c4d2e8f0a5b6c754d8e9f0adc3d4e5f6a7dc9d0e1f2a3b4c5d6e7f8a9b0',
            },
        });
        setProductCategories(response_.data);

    };
    const fetchStores = async () => {
        const header = {
            headers: {
                'ENCRYKEY': '3a7f1b9c4d2e8f0a5b6c754d8e9f0adc3d4e5f6a7dc9d0e1f2a3b4c5d6e7f8a9b0',
            },
        };
        const response = await axios.get(`${API_URL}/distinctStoreIds`, header);
        setStores(response.data.response.map(store => ({
            value: store.storeCode,
            label: store.name
        })));
    };
    const handleQuantity = (id, quantity) => {
        if (quantity >= 0) {
            setProducts(products.map(p =>
                p._id === id ? { ...p, quantity: quantity } : p
            ));
        }

    };

    return (
        <div>
            <div className='row'>
                <div className='col-3'>
                    <div className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary" style={{ width: '280px' }}>
                        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                            <svg className="bi pe-none me-2" width="40" height="32"><use xlinkHref="#bootstrap" /></svg>
                            <span className="fs-4">Catégorie</span>
                        </a>
                        <hr />
                        <ul className="nav nav-pills flex-column mb-auto">
                            {productCategories.map(x => <li className="nav-item">
                                <a className={`nav-link ${x._id == selectCategory ? 'active' : ''}`} onClick={(e) => setSelectedCategory(x._id)} aria-current="page">
                                    <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#home" /></svg>
                                    {x.name}
                                </a>
                            </li>)
                            }
                            <li className="nav-item">
                                <a className={`nav-link ${null == selectCategory ? 'active' : ''}`} onClick={(e) => setSelectedCategory(null)} aria-current="page">
                                    <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#home" /></svg>
                                    Tout
                                </a>
                            </li>
                        </ul>
                     <br/>
                     <div className='position-sticky top-0 pt-3' style={{ zIndex: 1 }}>
    <h4 className="d-flex justify-content-between align-items-center mb-3 mt-3">
        <span className="text-primary">Votre Panier</span>
        <span className="badge bg-primary rounded-pill">{products.filter(x => x.quantity > 0).length}</span>
    </h4>
    <ul className="list-group mb-3 bg-light">
        {products.filter(x => x.quantity > 0).map(product => (
            <li key={product.id} className="list-group-item d-flex justify-content-between lh-sm">
                <div>
                    <h6 className="my-0">{product.name}</h6>
                    <small className="text-body-secondary">x{product.quantity}</small>
                </div>
                <span className="text-body-secondary">{product.regular_price * product.quantity}</span>
            </li>
        ))}

        <li className="list-group-item d-flex justify-content-between">
            <span>Total (MAD)</span>
            <strong>{products.reduce((total, product) => {
                return total + (product.quantity * product.regular_price);
            }, 0)}</strong>
        </li>
    </ul>
</div>
                    </div>
                </div>
                <div className='col-9'>

                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        {products
                            .filter((f) => (f.category === selectCategory) || (selectCategory==null)?true:false)
                            .map((product) => (
                                <div className="col" key={product._id}>
                                    <div className={`card shadow-sm ${(product.quantity>0)?'bg-leceladon':''}`}>
                                        {/* Clickable Div for Modal */}
                                        <div
                                            className="w-100"
                                            style={{
                                                height: "300px",
                                                backgroundImage: `url("${process.env.REACT_APP_DATA_PIC_PATH}/${product.url_product}")`,
                                                backgroundSize: "cover",
                                                cursor: "pointer", // Add pointer cursor
                                            }}
                                            onClick={() => openModal(product)} // Open modal on click
                                        ></div>
                                      

                                        <div className="card-body">
                                            <h1 className="card-text">{product.name}</h1>
                                            <h3 className=" text-end">{product.regular_price} DHS</h3>
                                            {/* <a
                                                className="btn btn-dark"
                                                data-bs-toggle="collapse"
                                                href={`#collapse${product._id}`}
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls={`collapse${product._id}`}
                                            >
                                                Description ...
                                            </a>
                                          
                                            <div className="collapse" id={`collapse${product._id}`}>
                                                <div className="card card-body">{product.description}</div>
                                            </div> */}
                                        </div>
                                        <div className="card-footer">
                                            <div className="input-group mb-3">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => handleQuantity(product._id, product.quantity - 1)}
                                                    type="button"
                                                >
                                                    <i className="fa-solid fa-minus"></i>
                                                </button>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    min={0}
                                                    max={5}
                                                    value={product.quantity}
                                                />
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => handleQuantity(product._id, product.quantity + 1)}
                                                    type="button"
                                                >
                                                    <i className="fa-solid fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>

            </div>

            {selectedProduct && (
        <div
          className={`modal fade show`}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
            <div className="modal-header">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
              <div className="modal-body p-0">
                <img
                  src={`${process.env.REACT_APP_DATA_PIC_PATH}/${selectedProduct.url_product}`}
                  alt={selectedProduct.name}
                  className="img-fluid"
                  style={{ borderRadius: "8px" }}
                />
              </div>
             
            </div>
          </div>
        </div>
      )}
        </div>
    );

    
}

export default StoreShop;