import React from 'react';

function StoreCheckout({ products,formData,setFormData }) {
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    return (
        <div className="container">
            <main>


                <div className="row g-5">
                    <div className="col-md-5 col-lg-4 order-md-last">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-primary">Votre Panier</span>
                            <span className="badge bg-primary rounded-pill">{products.filter(x => x.quantity > 0).length}</span>
                        </h4>
                        <ul className="list-group mb-3">
                            {products.filter(x => x.quantity > 0).map(product => (
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">{product.name}</h6>
                                        <small className="text-body-secondary">x{product.quantity}</small>
                                    </div>
                                    <span className="text-body-secondary">{product.regular_price *product.quantity}</span>
                                </li>
                            ))}


                            <li className="list-group-item d-flex justify-content-between">
                                <span>Total (MAD)</span>
                                <strong>{products.reduce((total,product)=>{
                                    return total+ (product.quantity*product.regular_price);
                                },0)}</strong>
                            </li>
                        </ul>

                       
                    </div>
                    <div className="col-md-7 col-lg-8">
                        <h4 className="mb-3">Informations</h4>
                        <form className="needs-validation" noValidate>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <label htmlFor="firstName" className="form-label">Nom Complet</label>
                                    <input type="text" className="form-control" id="firstName" placeholder="" value={formData.name} required />
                                    <div className="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label htmlFor="lastName" className="form-label">Téléphone</label>
                                    <input type="text" className="form-control" id="lastName" placeholder="" value={formData.phone} required />
                                    <div className="invalid-feedback">
                                        Valid last name is required.
                                    </div>
                                </div>

                             </div>
                            <hr className="my-4" />

                            <h4 className="mb-3">Payment</h4>

                            <div className="my-3">
                                <div className="form-check">
                                    <input id="credit" onChange={handleChange} name="payment" type="radio" value="cash" className="form-check-input" defaultChecked required />
                                    <label className="form-check-label" htmlFor="credit">Espèce</label>
                                </div>
                                <div className="form-check">
                                    <input id="debit" onChange={handleChange} name="payment" type="radio" value="card" className="form-check-input" required />
                                    <label className="form-check-label" htmlFor="debit">Carte Bancaire</label>
                                </div>
                               
                            </div>

                            <hr className="my-4" />

                          
                        </form>
                    </div>
                </div>
            </main>

            <footer className="my-5 pt-5 text-body-secondary text-center text-small">
                <p className="mb-1">&copy; 2017–2025 Le Céladon</p>
                <ul className="list-inline">
                    <li className="list-inline-item"><a href="#">Privacy</a></li>
                    <li className="list-inline-item"><a href="#">Terms</a></li>
                    <li className="list-inline-item"><a href="#">Support</a></li>
                </ul>
            </footer>
        </div>
    );
}

export default StoreCheckout;