import { useEffect, useState } from "react";
import Select from "react-select";
function DeliveriesApp() {
    const [deliveries, setDeliveries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState();
    const status = "still"; // Exemple de statut, peut être dynamique

   
    useEffect(() => {
        const fetchAll = async () => {
            try {
                const response = await fetch(`https://office.le-celadon.ma/api.get.all.php?id=5a35f7b2a2fe78f714a20fd2e9583ed1&status=${status}`);
                const data = await response.json();
                setDeliveries(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des livraisons :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAll();
    }, [status]);
    const [cities] = useState({
        'MA001': 'Casablanca',
        'MA002': 'DAR BOUAZZA',
        'MA003': 'Bouskoura',
        'MA004': 'Mohamedia',
        'MA005': 'Benselimane',
        'MA006': 'Rabat',
        'MA007': 'Sale',
        'MA008': 'Temara',
        'MA009': 'Marrakech',
        'MA010': 'Ben Guerrir',
        'MA011': 'Agadir',
        'MA012': 'Tit Melil',
    });

    return (
        <div className="container mt-4">
            <h2 className="mb-4">Liste des Livraisons</h2>
            <div className="my-2">
                <input type="text" className="form-control" placeholder="Numéro de commande #17000" name="id" value={id} onChange={e => setId(e.target.value)} />
            </div>
            {loading ? (
                <div className="text-center">Chargement...</div>
            ) : (
                <div className="row">
                    {(id ? deliveries.filter(x => x.id == id) : deliveries).map((order) => (
                        <div key={order.id} className="col-md-4 mb-3">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h5 className="card-title">Commande #{order.id}</h5>
                                    <p className="card-text"><strong>Client :</strong> {order.shipping.first_name} {order.shipping.last_name}</p>
                                    <p className="card-text"><strong>Adresse :</strong> {order.shipping.address_1},<span className="fw-bold"> {Object.entries(cities).find(([key, value]) => key === order.shipping.state)?.[1]}</span>
                                    </p>
                                    <p className="card-text">
                                        <strong>Téléphone Bénénificiaire :</strong>
                                        <a href={`tel:${order.shipping?.shipping_phonenumber}`}>{order.shipping?.shipping_phonenumber}</a>
                                    </p>
                                    <p className="card-text">
                                        <strong>Téléphone Commandeur :</strong>
                                        <a href={`tel:${order.billing?.phone}`}>{order.billing?.phone}</a>
                                    </p>

                                    <p className="card-text"><strong>Total :</strong> {order.total} {order.currency}</p>
                                    <p className="card-text"><strong>Date :</strong> {order.meta_data.find(x => x.key == "Date de Livraison")?.value}</p>
                                    <p className="card-text"><strong>Status :</strong> <span className="badge bg-primary">{order.status}</span></p>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DeliveriesApp;
