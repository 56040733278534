import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import OpenAI from "openai";

const AddProductWebsite = () => {
  const [categoriesOptions, setCategoriesOption] = useState([]);
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [picture, setPicture] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_CLIENT_WEBSITE}:${process.env.REACT_APP_API_SECRET_WEBSITE}`)
    };

    axios.get(`${process.env.REACT_APP_WEBSITE_API}/wp-json/wc/v3/products/categories?per_page=100`, { headers })
      .then(response => {
        const categories = response.data.map(x => ({ value: x.id, label: x.name }));
        setCategoriesOption(categories);
      })
      .catch(error => console.error('Error:', error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('categories', selectedCategories.map(cat => cat.value));
    if (picture) {
      formData.append('picture', picture);
    }

    axios.post('https://office.le-celadon.ma/uploadimagesgetUrl.php', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => {
        if (response.data.success) {
          createWooCommerceProduct(response.data.filename);
        } else {
          alert('Erreur: ' + response.data.error);
        }
      })
      .catch(error => console.error('Error:', error))
      .finally(() => setLoading(false));
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width !== img.height) {
        setError("L'image doit être carrée.");
        setPicture(null);
        setPreview(null);
        return;
      }
      if (img.width < 500 || img.height < 500) {
        setError("L'image  doit  dépasser 500px.");
        setPicture(null);
        setPreview(null);
        return;
      }

      setError('');
      setPicture(file);
      setPreview(URL.createObjectURL(file));
    };
  };

  const createWooCommerceProduct = (imagePath) => {
    const data = {
      name: productName,
      type: 'simple',
      regular_price: price,
      description: description,
      categories: selectedCategories.map(cat => ({ id: cat.value })),
      images: [{ src: process.env.REACT_APP_DATA_PIC_PATH + "/" + imagePath }]
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_CLIENT_WEBSITE}:${process.env.REACT_APP_API_SECRET_WEBSITE}`)
    };

    axios.post(`${process.env.REACT_APP_WEBSITE_API}/wp-json/wc/v3/products`, data, { headers })
      .then(response => {
        alert('Produit créé: ' + response.data.name);
        resetForm();
      })
      .catch(error => console.error('Erreur lors de la création du produit:', error));
  };

  const resetForm = () => {
    setProductName('');
    setPrice('');
    setDescription('');
    setSelectedCategories([]);
    setPicture(null);
    setPreview(null);
  };

  return (
    <div className="container mt-0">
      <h1>Ajouter un produit</h1>
      <div className='row'>
        <div className='col-md-2 col-sm-0'></div>
        <div className='col-md-6 col-sm-12'>
        <form onSubmit={handleSubmit} className="container mt-5">
        <div className="mb-3">
          <label htmlFor="picture" className="form-label">Image</label>
          <input
            type="file"
            className="form-control"
            id="picture"
            onChange={handlePictureChange}
            disabled={loading}
          />
          {error && <p className="text-danger">{error}</p>}
         <div className='text-center'> {preview && <img src={preview} alt="Prévisualisation" className="mt-2" style={{ maxWidth: '250px', maxHeight: '250px' }} />}</div>
        </div>
        <div className="mb-3">
          <label htmlFor="productName" className="form-label">Nom du Produit</label>
          <input
            type="text"
            className="form-control"
            id="productName"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label">Prix</label>
          <input
            type="number"
            className="form-control"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="categories" className="form-label">Catégories</label>
          <Select
            id="categories"
            isMulti
            options={categoriesOptions}
            value={selectedCategories}
            onChange={setSelectedCategories}
            isDisabled={loading}
          />
        </div>
     
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'En cours...' : 'Soumettre'}
        </button>
      </form>

        </div>
        <div className='col-md-2 col-sm-0'></div>
      </div>
     
    </div>
  );
};

export default AddProductWebsite;
