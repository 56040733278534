
import Login from './pages/Login';
import DashBoard from './pages/DashBoard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useAuth from './components/useAuth';
import CmiAccounting from './pages/CmiAccounting';
import AddProductWebsite from './pages/AddProductWebsite';
import ListofProducts from './pages/ListofProducts';
import PrivateRoute from './components/PrivateRoute';
import OrdersGlovo from './pages/OrdersGlovo';
import MapComponent from './pages/MapComponent';
import dotenv from 'react-dotenv';
import StockByStore from './pages/StockByStore';
import StockUserHandle from './pages/StockUserHandle';
import CarrefourEcommerce from './pages/CarrefourEcommerce';
import StoreQuickProduct from './pages/StoreQuickProduct';
import StoreExpress from './pages/StoreExpress';
import DeliveriesApp from './pages/DeliveriesApp';

import BarcodePage from './pages/BarcodePage';

// Charger les variables d'environnement


function App() {
  return (
    <>
    
      <Routes>
      <Route  path="cmiExcel" element={ <PrivateRoute ><CmiAccounting /></PrivateRoute>} />
      <Route path='/addproductsite' element={<PrivateRoute ><AddProductWebsite /></PrivateRoute>} />
      <Route path='/productList' element={<PrivateRoute ><ListofProducts /></PrivateRoute>} />
      <Route path="/dashboard" element={<PrivateRoute ><DashBoard /></PrivateRoute>} />
      <Route path="/stockhandling" element={<PrivateRoute ><StockUserHandle selectedTag="7" selectedDefaultWarehouse="3" /></PrivateRoute>} />
      <Route path="/carrefourecommerce" element={<PrivateRoute ><CarrefourEcommerce selectedTag="7" selectedDefaultWarehouse="3" /></PrivateRoute>} />
      <Route path='/storeQuickProduct' element={<PrivateRoute ><StoreQuickProduct /></PrivateRoute>}/>
      <Route path='/ordersGlovo' element={<PrivateRoute ><OrdersGlovo /></PrivateRoute>}/>
      <Route path='/stockHouse' element={<PrivateRoute ><StockByStore /></PrivateRoute>}/>
      <Route path='/tracker' element={<PrivateRoute ><MapComponent /></PrivateRoute>}/>
      <Route path='/barcode' element={<PrivateRoute ><BarcodePage /></PrivateRoute>}/>
      <Route path='/storeExpress/:id' element={<StoreExpress />} />
 
      <Route path='/deliveries' element={<DeliveriesApp />} />
      <Route path='/login' element={<Login />} />
    
    
      <Route index element={<PrivateRoute ><DashBoard /></PrivateRoute>} />
    </Routes>

    </>
  );
}

export default App;
