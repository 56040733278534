import React, { useState } from "react";
import StoreFormData from "./store/StoreFormData";
import StoreShop from "./store/StoreShop";
import StoreCheckout from "./store/StoreCheckout";
import axios from "axios";
import StoreFinalStep from "./store/StoreFinalStep";
import { useParams } from "react-router-dom";
function StoreExpress(props) {
  const [step, setStep] = useState(1);
  const [products, setProducts] = useState([]);
  const [stepNames, setStepNames] = useState(['Informations', "Shop", 'Details de la commande', 'Confirmation']);
  const { id } = useParams("qdfsdf");

  const [productCategories, setProductCategories] = useState([]);
  const totalSteps = 4;
  const [formData, setFormData] = useState({ name: "", phone: "", payment: "cash" });
  const [info, setInfo] = useState({ id: null, ref: null, payment: "cash" });
  const nextStep = () => {
    if (step < 4) setStep(step + 1);
    if (step === 3) {
      gunchData();
    }
  };
  const gunchData = () => {

    if (products && formData) {
      let data_doli_invoice = {
        "mode_reglement_id": (formData.payment == "cash") ? "4" : '6',
        "cond_reglement_id": "0",
        "total_ht": products.reduce((total, product) => {
          return total + (product.quantity * product.regular_price);
        }, 0),
        "total_tva": "0.00000000",
        "total_ttc": products.reduce((total, product) => {
          return total + (product.quantity * product.regular_price);
        }, 0),
        "lines": products.filter(x => x.quantity > 0).map(product => ({
          "total_ttc": product.regular_price * product.quantity,
          "product_type": "0",
          "desc": product.name,
          "description": product.name,
          "qty": product.quantity,
          "subprice": product.regular_price,
          "tva_tx": "0.000",
        })),
        "date_creation": Math.floor(Date.now() / 1000),
        "date_validation": Math.floor(Date.now() / 1000),
        "socid": "328",
        "paye": "0",
        "date": Math.floor(Date.now() / 1000),
        "mode_reglement_code": (formData.payment == "cash") ? "LIQ" : 'CB',
        "module_source": "takepos",
        "pos_source": "1",
      };

      // Second API call (to /posadd)
      axios.post(`${process.env?.REACT_APP_PEGASUS_API_URL}/posadd`, data_doli_invoice, {
        headers: {
          'ENCRYKEY': '3a7f1b9c4d2e8f0a5b6c754d8e9f0adc3d4e5f6a7dc9d0e1f2a3b4c5d6e7f8a9b0',
        },
      }).then(res => {
        if (res.data.result) {
          // First API call (to /addOrder) nested inside the success of the second call
          let data = {
            "productType": "borne",
            "products": products.filter(x => x.quantity > 0).map(product => ({
              "title": product.name,
              "urlImage": `${process.env.REACT_APP_DATA_PIC_PATH}/${product.url_product}`,
              "quantity": product.quantity,
              "price": product.quantity * product.regular_price,
              "options": []
            })),
            "store_id": id,
            "orderCode": res.data.id,
            "orderNumber": res.data.ref,
            "CustomerName": formData.name,
            "clientPhone": formData.phone,
            "productsCount": products.filter(x => x.quantity > 0).length,
            "orderTime": new Intl.DateTimeFormat("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false, // Use 24-hour format
              timeZone: "Europe/Paris", // Set timezone to GMT+1 (Paris time)
            }).format(new Date()) + " GMT+1",
            "orderStatus": "paymentwait",
            "orderDate": new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date()),
            "totalPrice": products.reduce((total, product) => {
              return total + (product.quantity * product.regular_price);
            }, 0),
            "__v": 0
          };
          setInfo({ id: res.data.id, ref: res.data.ref, payment: formData.payment });

          axios.post(`${process.env?.REACT_APP_PEGASUS_API_URL}/addOrder`, { order: data }, {
            headers: {
              'ENCRYKEY': '3a7f1b9c4d2e8f0a5b6c754d8e9f0adc3d4e5f6a7dc9d0e1f2a3b4c5d6e7f8a9b0',
            },
          }).then(e => {
            if (e.data.result) {
              console.log("Order added successfully:", e.data);
              setStep(4);
            }
          }).catch(error => {
            console.error("Error adding order:", error);
          });
        }
      }).catch(error => {
        console.error("Error adding invoice:", error);
      });
    }

  }
  const returnStep = ()=>{


    setFormData({ name: "", phone: "", payment: "cash" });
    setInfo({ id: null, ref: null, payment: "cash" });
    setStep(1);
  }

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };
  return (
    <div className="card  shadow-sm d-flex flex-column" style={{ height: '100vh' }}>
      <h2 className="text-center" style={{ paddingTop: 25, paddingBottom: 25, backgroundColor: "white" }}>
        <img src="https://www.le-celadon.ma/wp-content/uploads/2021/07/logo_H_V.png" width={150} className="mx-3" />
        {stepNames[step - 1]}
      </h2>
      <div className="mt-4 flex-grow-1 d-flex flex-column justify-content-center">
        {step === 1 && <div className="p-3 "><StoreFormData formData={formData} setFormData={setFormData} /></div>}
        {step === 2 && <div className="p-3 "><StoreShop products={products} setProducts={setProducts} setProductCategories={setProductCategories} productCategories={productCategories} /></div>}
        {step === 3 && <div className="p-3 "><StoreCheckout products={products} formData={formData} setFormData={setFormData} /></div>}
        {step === 4 && <div className="p-3 "><StoreFinalStep infos={info} setStep={setStep} /></div>}
      </div>
     <div className="custom-buttons-container d-flex mt-4 pt-2 w-100">
        <button className="btn btn-lg btn-secondary flex-grow-1" onClick={prevStep} disabled={step === 1 || step ===4}>
          Previous
        </button>
        <button className="btn btn-lg btn-info flex-grow-1" onClick={(step==4)?returnStep:nextStep} disabled={
         
          (!formData.name.trim() || !formData.phone.trim()) ||
          (step === 3 && !products.some(x => x.quantity > 0))
        }>
          {(step == 3) ? "Valider" : (step==4)?"Nouvelle Commande":"Next"}
        </button>
      </div>
      
    </div>
  );
}

export default StoreExpress;