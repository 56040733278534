import axios from 'axios';
import React, { useState, useEffect } from "react";
import Select from "react-select";
import generateBarcode from '../components/barcodeHelper';
import ProductTable from './products/ProductTable';
import Modal from '../components/Modal';
import ProductDetails from './products/ProductDetails';
const API_URL = process.env?.REACT_APP_PEGASUS_API_URL;

const StoreQuickProduct = () => {
    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]); // Store list for dropdown
    const [picture, setPicture] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [productCategories, setProductCategories] = useState([]);
    const [defaultData, setDefaultData] = useState({
        name: '',
        description: '',
        regular_price: '',
        discounted_price: '',
        category: null,
        url_product: '',
        availability: true,
        barcode: generateBarcode()?.barcodeValue,
        details: [],
        type: "borne",
        stores: [] // Updated to handle store-specific availability
    });
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        regular_price: '',
        discounted_price: '',
        url_product: '',
        barcode: generateBarcode()?.barcodeValue,
        category: null,
        availability: true,
        details: [],
        type: "borne",
        stores: [] // Updated to handle store-specific availability
    });
    const [editingProduct, setEditingProduct] = useState(null); // New state for editing product
    useEffect(() => {
        fetchProducts();
        fetchStores(); // Fetch stores for dropdown
    }, []);

    const fetchProducts = async () => {
        const response = await axios.get(`${API_URL}/products`, {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        });
        setProducts(response.data);
        const response_ = await axios.get(`${API_URL}/productsCategories`, {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        });
        setProductCategories(response_.data);

    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleModify = async (e) => {
        e.preventDefault();

        const formData_ = new FormData();
        formData_.append('productName', formData.name);
        formData_.append('price', formData.regular_price);
        formData_.append('description', formData.description);
        formData_.append('type', formData.type);

        if (picture) {
            formData_.append('picture', picture);
            axios.post('https://office.le-celadon.ma/uploadimagesgetUrl.php', formData_, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.success) {
                        const f = async (url) => {
                            formData.url_product = url;

                            await axios.put(`${API_URL}/products/${editingProduct._id}`, formData, {
                                headers: {
                                    'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
                                },
                            }).then(response => {
                                if (response.data.success) {
                                    alert(`Produit modifié: ${response.data.product.name}`);
                                    setEditingProduct(null); // Reset editing mode
                                }
                            });

                            fetchProducts();
                        }

                        f(response.data.filename);

                    } else {
                        alert('Error: ' + response.data.error);
                    }
                })
                .catch(error => { });
        }
    };
    const handleEditClick = (product) => {
        console.log(product);
        setEditingProduct(product); // Set the product to edit
        setFormData({
            name: product.name,
            description: product.description,
            regular_price: product.regular_price,
            discounted_price: product.discounted_price,
            category: product.category || null,
            stores: product.stores || [], // Ensure stores are set
            type: product.type ?? "borne",
            barcode: product.barcode ?? generateBarcode()?.barcodeValue
        });
    };
    const handleStoreIdsChange = (selectedOptions) => {
        setFormData({
            ...formData,
            stores: selectedOptions.map(option => ({
                store_id: option.value,
                availability: true // Default availability to true for each store
            }))
        });
    };
    const handleproductCategoryChange = (selectedOptions) => {
        console.log(selectedOptions);
        setFormData({
            ...formData,
            category: selectedOptions.value
        });
    };


    const handleStoreAvailabilityChange = (storeId, availability) => {
        setFormData({
            ...formData,
            stores: formData.stores.map(store =>
                store.store_id === storeId ? { ...store, availability } : store
            )
        });
    };

   
    //     e.preventDefault();
    //     const formData_ = new FormData();
    //     formData_.append('productName', formData.name);
    //     formData_.append('price', formData.regular_price);
    //     formData_.append('description', formData.description);

    //     if (picture) {
    //         formData_.append('picture', picture);
    //         axios.post('https://office.le-celadon.ma/uploadimagesgetUrl.php', formData_, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         })
    //             .then(response => {
    //                 if (response.data.success) {
    //                     const f = async (url) => {
    //                         formData.url_product = url;
    //                         const method = editingProduct ? 'put' : 'post'; // Check if editing or adding a new product
    //                         const apiEndpoint = editingProduct
    //                             ? `${API_URL}/products/${editingProduct._id}`
    //                             : `${API_URL}/products`;

    //                         await axios[method](apiEndpoint, formData, {
    //                             headers: {
    //                                 'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
    //                             },
    //                         }).then(response => {
    //                             if (response.data.success) {
    //                                 alert(`Le Produit ajouté est : ${response.data.product.name}`);
    //                                 setEditingProduct(null); // Reset editing state
    //                             }
    //                         });

    //                         fetchProducts();
    //                     }

    //                     f(response.data.filename);

    //                 } else {
    //                     alert('Error: ' + response.data.error);
    //                 }
    //             })
    //             .catch(error => {});
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Check if required fields are filled
            if (!formData.name || !formData.regular_price || !formData.description) {
                alert("Please fill out all required fields.");
                return;
            }

            // Determine if we're adding or updating
            const isAdding = !editingProduct;

            // If adding and a picture is provided, upload the picture first
            if (isAdding && picture) {
                const formData_ = new FormData();
                formData_.append('productName', formData.name);
                formData_.append('price', formData.regular_price);
                formData_.append('description', formData.description);
                formData_.append('picture', picture);

                // Upload the picture
                const uploadResponse = await axios.post(
                    'https://office.le-celadon.ma/uploadimagesgetUrl.php',
                    formData_,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (!uploadResponse.data.success) {
                    alert('Error uploading picture: ' + uploadResponse.data.error);
                    return;
                }

                // Add the picture URL to the form data
                formData.url_product = uploadResponse.data.filename;
            }

            // Determine the API endpoint and method
            const method = isAdding ? 'post' : 'put';
            const apiEndpoint = isAdding
                ? `${API_URL}/products`
                : `${API_URL}/products/${editingProduct._id}`;

            // Send the request to add or update the product
            const response = await axios[method](apiEndpoint, formData, {
                headers: {
                    ENCRYKEY: 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c', // Add any required headers
                },
            });

            // Handle the response
            if (response.data.success) {
                setFormData(defaultData);
                alert(
                    `Product ${isAdding ? 'added' : 'updated'}: ${response.data.product.name}`
                );
                setEditingProduct(null); // Reset editing state
                fetchProducts(); // Refresh the product list
            } else {
                alert('Error: ' + response.data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };
    const updateAvailability = async (productId, storeId, availability) => {
        try {
            await axios.post(
                `${API_URL}/products/${productId}/store/${storeId}/availability`,
                { availability },
                { headers: { "Content-Type": "application/json", 'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c' } }
            ).then(r => {
                console.log("Disponibilité mise à jour :", r.data);
                fetchProducts();
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error.response?.data || error.message);
        }
    };

    const updateGlobalAvailability = async (productId, availability) => {
        try {
            const response = await axios.post(
                `${API_URL}/products/${productId}/availability`,
                { availability },
                { headers: { "Content-Type": "application/json", 'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c' } }
            );
            fetchProducts();
            console.log("Disponibilité mise à jour :", response.data);
            return response.data;
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error.response?.data || error.message);
            return { success: false, message: error.response?.data?.message || "Erreur inconnue" };
        }
    };

    const handleDelete = async (id) => {
        await axios.delete(`${API_URL}/products/${id}`, {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        }).then(response => {
            alert(response.data.message);
        });
        fetchProducts();
    };

    const handlePictureChange = (e) => {
        setPicture(e.target.files[0]);
    };

    const fetchStores = async () => {
        const header = {
            headers: {
                'ENCRYKEY': 'd721a498b6f1ff55f1eb4dfb197825eaa3b7e75491o747a719c30b9d6310ac8c',
            },
        };
        const response = await axios.get(`${API_URL}/distinctStoreIds`, header);
        setStores(response.data.response.map(store => ({
            value: store.storeCode,
            label: store.name
        })));
    };

    return (
        <div className="container-fluid mt-4">
            <h2>Product Management <button className='btn btn-primary' onClick={() => { setEditingProduct(null); setFormData(defaultData); }}>Ajouter un Produit</button></h2>
            <h2>{editingProduct ? 'Modify Product' : 'Add Product'}</h2>
            <form onSubmit={handleSubmit} className="mb-4">
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input type="text" className="form-control" name="description" value={formData.description} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Code à Bar</label>
                    <input type="text" className="form-control" name="barcode" readOnly={true} value={formData.barcode} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Regular Price</label>
                    <input type="number" className="form-control" name="regular_price" value={formData.regular_price} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Discounted Price</label>
                    <input type="number" className="form-control" name="discounted_price" value={formData.discounted_price} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="picture" className="form-label">Picture</label>
                    <input type="file" className="form-control" id="picture" onChange={handlePictureChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Catégorie</label>
                    <Select
                        placeholder="Selectionner la catégorie du produit"
                        options={productCategories.map(m => ({ value: m._id, label: m.name }))}
                        value={productCategories.map(m => ({ value: m._id, label: m.name })).find(x => x.value == formData.category) || null}
                        onChange={handleproductCategoryChange}

                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Store IDs</label>
                    <Select
                        isMulti
                        options={stores}
                        value={formData.stores.map(store => ({ value: store.store_id, label: (stores.find(s => s.value == store.store_id)?.label) }))}
                        onChange={handleStoreIdsChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <button type="submit" className="btn btn-primary">{editingProduct ? 'Update Product' : 'Add Product'}</button>
            </form>

          {(products && products.length &&  <ProductTable
                products={products ?? []}
                productCategories={productCategories}
                stores={stores}
                updateGlobalAvailability={updateGlobalAvailability}
                updateAvailability={updateAvailability}
                handleEditClick={handleEditClick}
                handleDelete={handleDelete}
                setCurrentProduct={setCurrentProduct}
            /> )}
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                Open Modal
            </button>

            <Modal modalId="exampleModal"  title="Details du produit">
                {(currentProduct  && stores && <ProductDetails product={currentProduct ?? defaultData} productCategories={productCategories} stores={stores} /> )}
            </Modal>
        </div>
    );
};

export default StoreQuickProduct;
