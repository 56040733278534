import JsBarcode from "jsbarcode";

 const generateBarcode = () => {
  const calculateCheckDigit = (base) => {
    const digits = base.split("").map(Number);
    let sum = 0;
    for (let i = 0; i < digits.length; i++) {
      sum += (i % 2 === 0 ? digits[i] : digits[i] * 3);
    }
    return (10 - (sum % 10)) % 10;
  };

  const generateRandomGTIN13 = () => {
    const countryCode = "611"; // Morocco country code
    const productCode = Math.floor(100000000 + Math.random() * 900000000); // Random 9 digits
    const baseGTIN13 = countryCode + productCode;
    const checkDigit = calculateCheckDigit(baseGTIN13);
    return baseGTIN13 + checkDigit;
  };

  const barcodeValue = generateRandomGTIN13();

  // Generate the barcode as a Data URL
  const svgElement = document.createElement("svg");
  JsBarcode(svgElement, barcodeValue, {
    format: "EAN13",
    lineColor: "#0b0b0b",
    width: 2,
    height: 100,
    displayValue: true,
  });

  return { barcodeValue, svgHTML: svgElement.outerHTML };
};

export default generateBarcode;