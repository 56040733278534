import React from 'react';

function StoreFinalStep({infos}) {
    return (
        <div className='row'>
            <div className='text-center col-12 mb-5'>
                    <img src="/adminKit/img/photos/progresscheck.gif" width={150} />
                    <h1 className='montserrat-italic ' style={{fontSize:"30pt"}}>Commande reçu</h1>
                    <h2 className='montserrat-italic fs-1'>N° {infos.id}</h2>
                    <h6>{infos.ref}</h6>
            </div>

            <div className='text-center'>
                <h1>Please go Forward to the Cash Desk</h1>
                <h1>Merci de se rendre à la caisse</h1>
                <h1>من فضلك، توجّه إلى الصندوق للدفع</h1>

            </div>
            <div className='my-3'>
            <h1 className='text-center'>Méthode de paiement <span class="fw-bold">{(infos?.payment=="cash")?" Espèce / Cash":'Carte Bancaire / Card'}</span></h1>
            </div>
            <div className='text-center'>
                <img src='/adminKit/img/photos/thisway.gif' width={200} />
            </div>
            
        </div>
    );
}

export default StoreFinalStep;