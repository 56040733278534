import React, { useState, useEffect } from "react";
import generateBarcode  from "../components/barcodeHelper";

const BarcodePage = () => {
  const [barcode, setBarcode] = useState("");
  const [barcodeSVG, setBarcodeSVG] = useState("");

  useEffect(() => {
    const { barcodeValue, svgHTML } = generateBarcode();
    setBarcode(barcodeValue);
    setBarcodeSVG(svgHTML);
  }, []);

  return (
    <div>
      <h1>GTIN-13 Barcode Generator</h1>
      <p>GTIN-13: {barcode}</p>
      <div dangerouslySetInnerHTML={{ __html: barcodeSVG }} />
    </div>
  );
};

export default BarcodePage;
